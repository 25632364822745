import React, { useState, useEffect } from "react";
import { useUserAuth } from "../components/UserAuthContext";
import { MdVerified } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/Navbar.module.css";
import logo from "../images/logo/logo_white.svg";
import stars from '../images/home/starsIcon.svg';

const Navbar = () => {
  const location = useLocation();
  const { user, userData } = useUserAuth();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isTerms, setIsTerms] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const userType = userData?.type;
  const userName = userData?.nome;

  // Handle Navbar Visibility on Scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 2);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  // Update state based on the current route
  useEffect(() => {
    setDisabled(location.pathname === "/complete-signup");
    setIsTerms(location.pathname === "/terms");
  }, [location.pathname]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  // Render Desktop Links
  const renderDesktopLinks = () => (
    <div className="hidden md:flex items-center space-x-4">
      {user ? (
        <>
          {userType === "normal" && (
            <>
              <Link
                to={disabled ? "#" : "/pay"}
                className="text-white hover:font-bold px-3 radius-teseo text-sm font-medium transition-colors duration-300"
              >
                Piani
              </Link>
              <span className={styles.verticalSeparator}></span>
            </>
          )}
          <div className="flex items-center">
            {(userType === "lifetime" || userType === "premium") ? (
              <>
                <MdVerified size={15} className="text-white mx-1" />
                <Link
                  to={disabled ? "#" : "/profilo"}
                  className="text-white font-medium underline"
                >
                  Ave {userName}
                </Link>
              </>
            ) : (
              <Link
                to={disabled ? "#" : "/profilo"}
                className="text-white font-medium underline"
              >
                Ave {userName}
              </Link>
            )}
          </div>
        </>
      ) : (
        <>
          <Link
            to="/pay"
            className="text-white hover:font-bold px-3 radius-teseo text-sm font-medium transition-colors duration-300"
          >
            Piani
          </Link>
          <Link
            to="/login"
            className="text-white border border-white px-4 py-2 radius-teseo text-sm font-medium"
          >
            Accedi
          </Link>
          <Link
            to="/signup"
            className="text-black bg-white px-4 py-2 radius-teseo text-sm font-medium shadow-md transition-all duration-300 ease-in-out hover:shadow-lg"
          >
            Iscriviti gratis
          </Link>
        </>
      )}
    </div>
  );

  // Render Mobile Menu
  const renderMobileMenu = () => (
    isMenuOpen && (
      <div className="md:hidden bg-[#1f1f1f] px-4 py-2 space-y-2">
        {user ? (
          <>
            {userType === "normal" && (
              <Link
                to="/pay"
                onClick={closeMenu}
                className="block text-white font-medium px-3 py-2"
              >
                Piani
              </Link>
            )}
            <div className="flex items-center space-x-2">
              {(userType === "lifetime" || userType === "premium") && (
                <MdVerified size={15} className="text-white" />
              )}
              <Link
                to="/profilo"
                onClick={closeMenu}
                className="block text-white font-medium underline"
              >
                Ave {userName}
              </Link>
            </div>
          </>
        ) : (
          <>
            <Link
              to="/pay"
              onClick={closeMenu}
              className="block text-white font-medium px-3 py-2"
            >
              Piani
            </Link>
            <Link
              to="/signup"
              onClick={closeMenu}
              className="block text-white font-medium px-3 py-2"
            >
              Iscriviti gratis
            </Link>
          </>
        )}
      </div>
    )
  );

  return (
    <nav
      className={`background-teseo-component white w-full z-50 transition-all duration-500 transform ${visible ? "fixed top-0 left-0 opacity-100" : "absolute -translate-y-full"
        } ${isTerms && "hidden"}`}
    >
      <div className="max-w-7xl mx-auto flex justify-between items-center px-4 sm:px-6 lg:px-8 h-16">
        {/* Logo */}
        <Link to={disabled ? "#" : "/"}>
          <div className="flex items-center" onClick={closeMenu}>
            <img src={logo} alt="Logo" className={styles.nav_logo_image} />
            <span className="regular fontXL mx-2">Teseo.app</span>
          </div>
        </Link>

        {/* Desktop Links */}
        {renderDesktopLinks()}

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center space-x-4">
          {!user && (
            <Link
              to="/login"
              className="text-white border border-white px-4 py-2 radius-teseo text-sm font-medium"
            >
              Accedi
            </Link>
          )}
          <button
            onClick={toggleMenu}
            className="p-2 text-white focus:outline-none"
          >
            {isMenuOpen ? (
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {renderMobileMenu()}

      {userType === "normal" && (
        <div className="background-teseo inter-semibold text-white text-center py-1 d-flex flex-row justify-content-center px-5">
          <Link to={"/pay"} className="d-flex"><span className="me-2">Sblocca le funzionalità di Teseo+</span><img src={stars} className={`${styles.starsIcon}`} alt='img'></img></Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;