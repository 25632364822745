import React, { useState } from 'react';
import TranslationBox from './TranslationBox';
import Analysis from './analysis/Analysis';
import styles from '../styles/ResultsTabs.module.css';
import { FaPercent } from "react-icons/fa";
import traduzioneLogo from "../images/icons/traduzione-icon.svg"
import analisiLogo from "../images/icons/analisi-icon.svg"

const ResultsTabs = ({ translation, analysis, lemma, userData, loading, progress, lang }) => {
  const [activeTab, setActiveTab] = useState('translation');

  return (
    <div className="py-3 pb-0">
      <div className='d-flex mb-3'>
        <button
          onClick={() => setActiveTab('translation')}
          className={`${styles.tab} ${activeTab === 'translation' ? styles.activeTab : styles.inactiveTab}`}
        >
          <div className="d-flex align-items-center">
            <img src={traduzioneLogo} alt="Translation Icon" className="mr-2" width={24} height={24} />
            <span className='inter-medium'>Traduzione</span>
          </div>
        </button>
        <button
          onClick={() => setActiveTab('analysis')}
          className={`${styles.tab} ${activeTab === 'analysis' ? styles.activeTab : styles.inactiveTab}`}
        >
          <div className='d-flex align-items-center'>
            <img src={analisiLogo} alt="Translation Icon" className="mr-2" width={24} height={24} />
            <span className='inter-medium'>Analisi</span>
            <span
              className={`inter-regular fontS ml-3 d-flex align-items-center opacity-70 transition-opacity duration-500 ease-in-out ${progress < 100 ? 'opacity-100' : 'opacity-0'}`}
            >
              {(progress < 100 && progress > 0) && (
                <>
                  {Math.ceil(progress)}<FaPercent fontSize={8} />
                </>
              )}
            </span>
          </div>
        </button>
      </div>

      <div className={`${styles[activeTab]}`}>
        {activeTab === 'translation' && <TranslationBox object={translation} />}
        {activeTab === 'analysis' && <Analysis userData={userData} lang={lang} />}
      </div>
    </div>
  );
};

export default ResultsTabs;