import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Switch = ({ disabled }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isGreco = location.pathname === "/greco";
  const isLatino = location.pathname === "/";

  const togglePageLatino = () => {
    if (disabled) return;
    if (isGreco) {
      navigate("/");
    }
  };

  const togglePageGreco = () => {
    if (disabled) return;
    if (isLatino) {
      navigate("/greco");
    }
  };

  return (
    <div
      className="d-flex gap-2 radius-teseo text-center px-2 py-2 fontS mb-4 border-1"
      style={{
        borderColor: "#666666",
        justifyContent: "space-between", 
        width: "50%",
        minWidth: "300px",
        backgroundColor: "#0C0C0C",
      }}
    >
      <span
        onClick={togglePageLatino}
        className={`${
          isLatino
            ? "background-teseo text-white radius-teseo"
            : "text-gray-500"
        } inter-medium text-center px-4 py-2 transition-all ease cursor-pointer radius-teseo`}
        style={{
          opacity: disabled ? 0.5 : 1, 
          pointerEvents: disabled ? "none" : "auto",
          width: "100%",
          letterSpacing: "1px",
        }}
      >
        Latino
      </span>
      <span
        onClick={togglePageGreco}
        className={`${
          isGreco
            ? "background-teseo text-white radius-teseo"
            : "text-gray-500"
        } inter-medium text-center px-4 py-2 transition-all ease cursor-pointer radius-teseo`}
        style={{
          opacity: disabled ? 0.5 : 1, 
          pointerEvents: disabled ? "none" : "auto", 
          width: "100%",
          letterSpacing: "1px",
        }}
      >
        Greco
      </span>
    </div>
  );
};

export default Switch;