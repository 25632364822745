import { createContext, useCallback, useContext, useState, useEffect } from "react";

const TextOperationsContext = createContext();

export function TextOperationsProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [loadingTranslation, setLoadingTranslation] = useState(false);
    const [loadingAnalysis, setLoadingAnalysis] = useState(false);
    const [error, setError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [progress, setProgress] = useState(0);

    const resetState = useCallback(() => {
        setLoading(false);
        setLoadingTranslation(false);
        setLoadingAnalysis(false);
        setError(null);
        setDisabled(false);
        setProgress(0);
    }, []);

    const [analysis, setAnalysis] = useState(() => {
        const savedAnalysis = localStorage.getItem('displayedAnalysis');
        return savedAnalysis ? JSON.parse(savedAnalysis) : {};
      });
    
    const [greekAnalysis, setGreekAnalysis] = useState(() => {
    const savedAnalysisGreek = localStorage.getItem('displayedAnalysisGreek');
    return savedAnalysisGreek ? JSON.parse(savedAnalysisGreek) : {};
    });

    const [lemma, setLemmas] = useState(() => {
    const savedAnalysisLemma = localStorage.getItem('displayedAnalysisLemma');
    return savedAnalysisLemma ? JSON.parse(savedAnalysisLemma) : {};
    });

    const [lemmaGreek, setLemmasGreek] = useState(() => {
    const savedAnalysisLemmaGreek = localStorage.getItem('displayedAnalysisLemmaGreek');
    return savedAnalysisLemmaGreek ? JSON.parse(savedAnalysisLemmaGreek) : {};
    });

    const [translation, setTranslation] = useState(() => {
        const savedTranslationLatin = localStorage.getItem('displayedTranslationLatin');
        return savedTranslationLatin ? savedTranslationLatin : '';
    }); 
    /* const [translation, setTranslation] = useState("");
     */
    const [greekTranslation, setGreekTranslation] = useState(() => {
        const savedTranslationGreek = localStorage.getItem('displayedTranslationGreek');
        return savedTranslationGreek ? savedTranslationGreek : '';
    });


    useEffect(() => {
        localStorage.setItem('displayedAnalysisGreek', JSON.stringify(greekAnalysis));
      }, [greekAnalysis]);
      useEffect(() => {
        localStorage.setItem('displayedAnalysis', JSON.stringify(analysis));
      }, [analysis]);
      useEffect(() => {
        localStorage.setItem('displayedAnalysisLemma', JSON.stringify(lemma));
      }, [lemma]);
      useEffect(() => {
        localStorage.setItem('displayedAnalysisLemmaGreek', JSON.stringify(lemma));
      }, [lemmaGreek]);


    const contextValue = { 
        //states
        loading,
        loadingTranslation,
        loadingAnalysis,
        error,
        disabled,
        progress,
        analysis,
        greekAnalysis,
        lemma,
        lemmaGreek,
        translation,
        greekTranslation,

        //setters
        setLoading,
        setLoadingAnalysis,
        setLoadingTranslation,
        setError,
        setDisabled,
        setProgress,
        setAnalysis,
        setGreekAnalysis,
        setLemmas,
        setLemmasGreek,
        setTranslation,
        setGreekTranslation,
        resetState
    }

    return (
        <TextOperationsContext.Provider value={contextValue}>
          {children}
        </TextOperationsContext.Provider>
      );
}

export const useTextOperations = () => {
    /* const uiState = useUIState();
    const analysisHook = useAnalysis(uiState);
    const translationHook = useTranslation(uiState);
  
    return {
      ...uiState,
      ...analysisHook,
      ...translationHook,
    }; */

    const context = useContext(TextOperationsContext);
    
    if (context === undefined) {
      throw new Error('useTextOperations must be used within a TextOperationsProvider');
    }
    
    return context;
  };
