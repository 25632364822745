import React, { useState } from "react";
import WrapperLemma from "./WrapperLemma";
import WrapperDeclension from "./WrapperDeclension";
import { useLemma } from "../../hooks/useLemma";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { motion } from "framer-motion";
import styles from "../../styles/WrapperWord.module.css";


export default function WrapperWord(props) {

    const { word, features, type } = props; // Destructure props for cleaner code
    const [isWordOpen, setIsWordOpen] = useState(false);

    // Function to render features inline
    const renderFeatures = () => {
        return Object.keys(features)
            .filter((featureKey) => featureKey !== 'significato' && featureKey !== 'pos' && featureKey !== 'lemma' && featureKey !== 'declinazione' && featureKey !== 'paradigma') // Exclude "significato"
            .map((featureKey) => {
                const featureValue = features[featureKey];
                return featureKey === 'persona' ? `${featureValue} ${featureKey}` : featureValue;
            }).join(', ');
    };

    const hasAdditionalFeatures = Object.keys(features).length > 0; // Check if there are features
    //const { lemmas, loading: lemmaLoading, error } = useLemma([word], type);

    const renderWord = () => (
        isWordOpen && (
            <>
                <WrapperLemma type={type} lemma={features.lemma}></WrapperLemma>
                <WrapperDeclension type={type} declinazione={features.declinazione} paradigma={features.paradigma}></WrapperDeclension>
                <div 
                    style={{
                        borderTop: '1px solid #666666',
                        margin: '0 -1rem',
                    }}
                />
                <div className="pt-1 mb-2">
                    <span className="inter-regular"
                        style={{
                            color: '#A5A5A5',
                        }}
                    >
                        {features.pos}
                    </span>
                    {hasAdditionalFeatures && (
                        <>
                            <span className="inter-regular me-1">,</span>
                            <span className="inter-regular me-3"
                                style={{
                                    color: '#A5A5A5',
                                }}
                            >
                                {renderFeatures()}
                            </span>
                        </>

                    )}
                    {hasAdditionalFeatures && <br />}

                    {/* Third line for the "significato" feature */}
                    {features.significato &&
                        <span className="inter-regular"
                            style={{
                                color: '#A5A5A5',
                            }}
                        >
                            {features.significato}
                        </span>
                    }
                </div>
            </>
        )
    );

    const toggleWord = () => setIsWordOpen(!isWordOpen);

    return (
        <div className={`${styles.wordBox} text-white mb-3`}>
            <span className="d-flex flex-row justify-between">
                <span className="fontXL inter-bold">{word}</span>
                <button
                    onClick={toggleWord}
                    className="p-2 text-white focus:outline-none"
                >
                    {isWordOpen ? (
                        <FaMinus />
                    ) : (
                        <FaPlus />
                    )}
                </button>
            </span>
            {/*
            <span className="fontL mx-1">{features.pos}</span>
            {hasAdditionalFeatures && (
                <span>
                    {renderFeatures()}
                </span>
            )}
            {hasAdditionalFeatures && <br />}

            {/* Third line for the "significato" feature 
            {features.significato && <span>{features.significato}</span>}

            <div>
                <br></br>
                <WrapperDeclension type={type} declinazione={features.declinazione} paradigma={features.paradigma}></WrapperDeclension>
                <WrapperLemma type={type} lemma={features.lemma}></WrapperLemma>
            </div>*/}
            <motion.div
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opactiy: 0 }}
            >
                {renderWord()}
            </motion.div>
        </div>
    );
}
