import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router";
import { useUserAuth } from "../components/UserAuthContext";
import styles from "../styles/TeseoPlusBanner.module.css";
import bannerDesktop from '../images/banner/banner-image-desktop.svg';
import bannerMobile from '../images/banner/banner-image-mobile.svg';
import ShareLink from './ShareLink';

const TeseoPlusBanner = () => {
    const navigate = useNavigate();
    const { userData } = useUserAuth();
    const userType = userData["type"];

    return (
        <div className={`${styles[userType]} background-teseo-component w-100`} style={{ borderRadius: '24px' }}>
            <Row className="d-flex justify-content-start flex-lg-row flex-column m-0">
                <Col lg={5} md={12} className={`${styles.leftSide} d-flex justify-content-center bg-white`}>
                    <div>
                        {(userType === 'normal' || userType === '') && <img src={bannerMobile} className={`${styles.imgMobile}`} alt='img'></img>}
                        {userType === 'lifetime' ?
                            <>
                                <p className={`${styles.title} black regular`} >Spargi la voce!</p>
                                <p className={`${styles.text} inter-medium black`}>Consiglia Teseo a un amico.</p>
                                <p className={`${styles.text} inter-medium black`}>Aiutaci a creare la nostra community!</p>
                                <Button id="bannerButton" onClick={() => navigate("/")} className={`${styles.unlock_button} inter-semibold d-flex flex-row justify-content-center align-items-center `} variant='dark'>
                                    <ShareLink />
                                </Button>
                            </> :
                            <>
                                <p className={`${styles.title} black regular`} >Sblocca Teseo+</p>
                                <ul className={`${styles.list} d-flex flex-column black FontM  list-unstyled`}>
                                    <li className="d-flex flex-row align-items-center">
                                        <span role="img" aria-label="green checkbox emoji" className={`${styles.margin}`}>✅</span>
                                        <span className={`${styles.text} inter-medium`}> 30 analisi al mese </span>
                                    </li>
                                    <li className="d-flex flex-row align-items-center">
                                        <span role="img" aria-label="green checkbox emoji" className={`${styles.margin}`}>✅</span>
                                        <span className={`${styles.text} inter-medium`}> Funzione scatta e traduci illimitata</span>
                                    </li>
                                    <li className="d-flex flex-row align-items-center">
                                        <span role="img" aria-label="green checkbox emoji" className={`${styles.margin}`}>✅</span>
                                        <span className={`${styles.text} inter-medium`}> Funzione dizionario illimitata</span>
                                    </li>
                                </ul>

                                <div className="d-flex flex-row align-items-center black">
                                    <span className={`${styles.comingSoon} inter-semibold`}> COMING SOON </span>
                                    <span className={`${styles.text} inter-semibold`}> Lezioni di grammatica</span>
                                </div>

                                <Button onClick={() => navigate("/pay")} className={`${styles.unlock_button} inter-semibold`} variant='dark'>
                                    Scegli il tuo piano
                                </Button>
                            </>
                        }
                    </div>
                </Col>

                {(userType === 'normal' || userType === '' || !userType) &&
                    <Col lg={6} className={`${styles.rightSide}`}>
                        <img src={bannerDesktop} className={`${styles.imgDesktop}`} alt='img'></img>
                    </Col>
                }
            </Row>
        </div>
    );
}

export default TeseoPlusBanner;
