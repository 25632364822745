import React from 'react';
import styles from '../styles/PaymentTeseo.module.css';
import { FaRegCircleCheck } from "react-icons/fa6";

const FreePlanCard = ({userType}) => {

    const features = [
        {
            text: 'Traduzioni illimitate',
        },
        {
            text: 'Fino a 5 analisi di latino e greco ogni mese',
        }
    ];

    return (
        <div className={`${styles.boxTeseo} background-teseo-component radius-teseo p-10 w-full`}>
            <div>
                {userType === "normal" && <span className='fontS regular'>Il tuo piano attuale</span> }
                <p className="fontXXL bold lh-0">Teseo</p>
            </div>

            <div>
                <div className='py-3'>
                    <span className='fontXL bold'>Gratis</span>
                </div>

                <div className='d-flex'>
                    <ul className="space-y-3">
                        {features.map((feature, index) => {
                            return (
                                <li key={index} className="d-flex align-items-center gap-2">
                                    <FaRegCircleCheck style={{ fontSize: "20px", minWidth: "20px", minHeight: "20px" }} className={`opacity-50`} />
                                    <span className='regular fontM'>{feature.text}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FreePlanCard;