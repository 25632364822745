// Login.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../components/UserAuthContext";
import styles from "../styles/PopupTeseo.module.css";

import api from '../API/backend';

const API_URL = process.env.REACT_APP_BACKEND_PATH;

const Login = () => {
    const { logIn, googleSignIn, resetPassword } = useUserAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [state, setState] = useState("auth");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            const success = await logIn(email, password, setError);
            if (success) {
                navigate('/', { replace: true });
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        setError("");
        try {
            const res = await googleSignIn();
            if (res.status === "registered") {
                console.log("Google Sign In success");
                localStorage.clear();
                navigate("/");
            } else if (res.status === "needs_registration") {

                const user_uid = res.uid;

                console.log("Google Registration process started");

                const response = await api.post(`${API_URL}/user/complete-signup`, { user_uid });
                console.log("Added basic doc to firebase");

                navigate("/complete-signup");
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await resetPassword(email);
            setState("reset-pass-2");
        } catch (error) {
            // TODO: parsare i messaggi di errore da firebase
            setError(error.message);
        }
    }

    return (
        <>
            <Container className="pt-40">
                <div className={styles.normalSizeBox}>
                    <div className="d-flex align-items-center justify-content-center background-teseo-component radius-teseo">
                        <div className={`${styles.boxTeseo} white p-5`}>
                            {state === "auth" && (
                                <>
                                    <h2 className="mb-3 bold text-center fontXL">Teseo ti stava aspettando</h2>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control
                                                type="email"
                                                placeholder="Indirizzo Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>

                                        <div className="d-grid gap-2">
                                            <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                                Accedi
                                            </Button>
                                        </div>
                                    </Form>
                                    <div className={styles.separator}>
                                        <span className={styles.separator_text}>Oppure</span>
                                        <hr style={{
                                            width: "100%",
                                            margin: "30px",
                                            borderTop: "2px solid #ccc",
                                        }} />
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <GoogleButton
                                            className="g-btn"
                                            type="light"
                                            onClick={handleGoogleSignIn}
                                        />
                                    </div>

                                    <div
                                        className="p-1 box mt-3 text-center regular fontM"
                                        onClick={() => setState("reset-pass-1")}
                                    >
                                        Password dimenticata?{" "}
                                        <span style={{ textDecoration: "underline", cursor: "pointer" }} className="linkTeseo">
                                            Resettala qui
                                        </span>
                                    </div>
                                    <div className="p-2 mt-3 text-center regular fontM">
                                        Non hai ancora un account? <Link to="/signup" className="linkTeseo">Registrati</Link>
                                    </div>
                                </>
                            )}
                            {state === "reset-pass-1" && (
                                <>
                                    <h2 className="mb-3 bold text-center fontXL">Recupera le tue credenziali</h2>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <Form onSubmit={handleResetPassword}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control
                                                type="email"
                                                placeholder="Inserisci la tua Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                        <div className="d-grid gap-2">
                                            <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                                Recupera credenziali
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            )}
                            {state === "reset-pass-2" && (
                                <>
                                    <h2 className="mb-3 bold text-center fontXL">Email inviata</h2>
                                    <img
                                        id="mail-icon"
                                        src={"mail-icon.png"}
                                        alt="Mail"
                                        style={{
                                            width: "150px",
                                            height: "auto",
                                            display: "block",
                                            margin: "0 auto",
                                        }}
                                    />
                                    <p
                                        className="text-center"
                                        style={{
                                            maxWidth: "400px", 
                                            margin: "20px auto",
                                        }}
                                    >
                                        Abbiamo inviato le istruzioni per il recupero delle credenziali al tuo
                                        indirizzo email. Controlla la inbox.
                                    </p>
                                    <div style={{ textAlign: "center" }}>
                                        <Button
                                            variant="primary"
                                            className="buttonTeseo bold fontL"
                                            onClick={() => setState("auth")}
                                        >
                                            Torna al login
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Login;
