import styles from '../styles/TextareaUser.module.css';
import React, { useState, useEffect } from 'react';
import { FaCamera } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


export default function TextareaUser(props) {
    const navigate = useNavigate();
    const isReadOnly = props.readOnly;
    const placeholder = props.initialize;
    const defaultValue = props.defaultValue;
    const user = props.user;
    const userData = props.userData;
    const { lang, startProcessVersion } = props;

    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (userData["cameraCount"] > 2) {
            setIsAuthorized(true);
        }
    }, [userData]);

    const handleStartButton = () => {
        startProcessVersion(lang);
    };

    const handleNormalUserButton = () => {
        navigate('/pay');
    }

    return (
        <div className={`${styles.textareaWrapper} h-80`}>
            <textarea
                name="postContent"
                placeholder={placeholder}
                rows={8}
                maxLength={1500}
                value={defaultValue}
                onChange={props.setTextInput}
                className={`${styles.textareaUser} h-full inter-regular white fontL bg-black p-4 ${lang === "grc" ? styles.greTextarea : styles.latTextarea}`}
                readOnly={isReadOnly}
            />
            <div className='mobile-only bottom-6 right-6 bg-black radius-teseo'>
                {props.loading && (
                    <div className={styles.loadingOverlay}>
                        <img src="loading-mobile.gif" alt="Loading" className={styles.loadingGif} />
                    </div>
                )}
                {userData.type === 'premium' || userData.type === 'lifetime' || isAuthorized ? (
                    <button
                        type="button"
                        className={`${styles.cameraButton} m-2 p-2`}
                        onClick={props.handleCameraClick}
                    >
                        <FaCamera size={18} />
                    </button>
                ) : (
                    <button
                        type="button"
                        className={`${styles.cameraButton} m-2 p-2`}
                        onClick={handleNormalUserButton}
                    >
                        <FaCamera size={18} />
                    </button>
                )}
                <button
                    type="button"
                    className={`${styles.startButton} p-2`}
                    onClick={handleStartButton}
                >
                    <span className='regular'>Chiedi a Teseo</span>
                </button>
            </div>
{/*             <CounterBanner lang={lang} user={user} userData={userData} />
 */}        </div>
    );
}