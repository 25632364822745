import { useState, useCallback } from 'react';
import divideText from '../utils/divideText';
import api from '../API/backend';
import { useTextOperations } from './TextOperationsContext';

const API_URL = process.env.REACT_APP_BACKEND_PATH;
const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
};

// TODO : BETTER HANDLING ERRORI

export const useTranslation = (uiState) => {
    /* const [translation, setTranslation] = useState(() => {
        const savedTranslationLatin = localStorage.getItem('displayedTranslationLatin');
        return savedTranslationLatin ? savedTranslationLatin : '';
    }); */
    /* const [translation, setTranslation] = useState("");
    const [greekTranslation, setGreekTranslation] = useState(() => {
        const savedTranslationGreek = localStorage.getItem('displayedTranslationGreek');
        return savedTranslationGreek ? savedTranslationGreek : '';
    }); */

    /* const { setError, setProgress } = uiState; */

    const { 
        translation,
        greekTranslation,
        setTranslation,
        setGreekTranslation, 
        setError,
        setProgress,
      } = useTextOperations();

    const handleUpdateError = useCallback((errorMessage) => {
        setError(errorMessage);
    }, []);

    const translateText = useCallback(async (inputText, lang) => {
        let textTranslated = "";

        try {
            /* if (lang === "grc") {
                textToTranslate = divideText(inputText, 70); // Divide the text into subtexts
                for (let i = 0; i < textToTranslate.length; i++) {
                    const response = await api.post(`${API_URL}/translate`, { text: textToTranslate[i], lang }); 
                    textTranslated += response.data.translatedText;
                }
            } else {
                const response = await api.post(`${API_URL}/translate`, { text: inputText, lang });
                console.log(response);
                textTranslated = response.data.translatedText; 
            } */

            const response = await api.post(`${API_URL}/translate`, { text: inputText, lang });
            textTranslated = response.data.translatedText;

            if (lang === "grc") {
                setGreekTranslation(textTranslated);
            }
            else {
                setTranslation(textTranslated);
            }
            localStorage.setItem(lang === "grc" ? 'displayedTranslationGreek' : 'displayedTranslationLatin', textTranslated);

            return ({
                status: true,
                outputTranslation: textTranslated
            })
        } catch (error) {
            handleTranslateError(error, handleUpdateError);

            return false
        }

    }, [handleUpdateError]);


    const handleTranslateText = useCallback(async (inputText, lang) => {
        try {
            setError('');
            setTranslation("");
            setProgress(0);

            return await translateText(inputText, lang);
        } catch (error) {
            setError(error.message);
            return false;
        } finally {

        }

    }, []);


    return {
        translation,
        greekTranslation,
        handleTranslateText,
    };
}

const handleTranslateError = (error, updateError) => {
    let errorMessage = 'Teseo non risponde'; 

    if (error.response) {
        const statusCode = error.response.status;
        errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
        updateError(errorMessage);
    } else if (error.request) {
        console.error('Request Error:', error.request);
    } else {
        console.error('Request Setup Error:', error.message);
    }

    throw new Error(errorMessage);
};