import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../API/backend';
const API_URL = process.env.REACT_APP_BACKEND_PATH;

export const useProcessVersion = ({
  user,
  inputText,
  userData,
  handleTranslateText,
  handleAnalyzeText,
  updateLimitInContext,
  trackEvent,
  setDisabled,
  setLoading,
  setLoadingAnalysis,
  setLoadingTranslation,
  resetState,
  setError,
  previousTextRef,
  dataReady
}) => {
  
  const [showVersionResults, setShowVersionResults] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openPopupTerms, setOpenPopupTerms] = useState(false);

  const saveProcessedVersion = useCallback(async (inputText, lang, translationOutput, analysisOutput) => {

    console.log(analysisOutput);

    try {
        await api.post(`${API_URL}/save`, {
            input: inputText,
            translation: translationOutput,
            analysis: analysisOutput,
            lang: lang,
        });
    } catch (error) {
        console.error('Error saving translation:', error);
    }
  }, []);


  const handleTranslate = useCallback(async (lang) => {
    setLoadingTranslation(true);
    try {
      const translationResult = await handleTranslateText(inputText, lang);
      
      if (translationResult) {
        trackEvent('Button', 'Click button translate latino', 'Translate latin text');

        return translationResult;
      }
    } catch (error) {
      console.error('Translation error:', error);
      throw error;
    }
    finally{
      setLoadingTranslation(false);
    }
  }, [inputText, handleTranslateText, trackEvent]);


  const handleAnalysis = useCallback(async (lang) => {
    setLoadingAnalysis(true);
    try {
      const analysisResult = await handleAnalyzeText(inputText, lang);
      
      if (analysisResult) {
        trackEvent('Button', 'Click button analisi latino', 'Analyzed latin text');
        return analysisResult;
      }
    } catch (error) {
      console.error('Analysis error:', error);
      throw error;
    }
    finally {
      setLoadingAnalysis(false);
    }
  }, [inputText, handleAnalyzeText, trackEvent]);


  const handleStartProcessVersion = useCallback(async (lang) => {
    setError(null);
  
    // Check if the user is logged in
    if (!user) {
      setOpenSignup(true);
      return;
    }
  
    // Check if terms are accepted
    if (user && !userData.termsAccepted) {
      setOpenPopupTerms(true);
      return;
    }
  
    // Check for empty input
    if (inputText === "") {
      setError(<span>Cosa vuoi chiedere a Teseo?</span>);
      return;
    }
  
    // Check if input text is the same as the previous one
    if (inputText === previousTextRef.current) {
      setError(<span>Cambia il testo da chiedere a Teseo!</span>);
      return;
    }

    if (!dataReady) { return }

    previousTextRef.current = inputText; // Update the ref to the current text
  
    // Reset state and prepare UI
    resetState();
    setShowVersionResults(true);
    setDisabled(true);
  
    try {
      // Perform translation
      const [translationResult] = await Promise.all([handleTranslate(lang)]);
  
      // Check user limits and perform analysis if allowed
      let analysisResult = null;
  
      if (
        (lang === "lat" && userData.latinoCount > 0) ||
        (lang === "grc" && userData.grecoCount > 0)
      ) {
        // Perform analysis and update limits
        [analysisResult] = await Promise.all([handleAnalysis(lang)]);
        await updateLimitInContext(lang);
      } else if (
        (lang === "lat" && userData.latinoCount <= 0) ||
        (lang === "grc" && userData.grecoCount <= 0)
      ) {
        setError(
          <span>
            Hai raggiunto il limite di analisi di {lang === "lat" ? "latino" : "greco" } per questo mese!
            {userData["type"] === "normal" && (
              <Link className="socialTeseo mx-1" to="/pay">
                Passa a Teseo+
              </Link>
            )}
          </span>
        );
      }
  
      // Save the processed data in all cases
      await saveProcessedVersion(
        inputText,
        lang,
        translationResult.outputTranslation,
        analysisResult?.outputAnalysis || null
      );
    } catch (error) {
      console.error("Error in process:", error);
    } finally {
      setDisabled(false); // Reset the loading and disabled states
    }
  }, [
    user,
    inputText,
    userData.latinoCount,
    userData.grecoCount,
    handleTranslate,
    handleAnalysis,
    updateLimitInContext,
  ]);
  




  return {
    handleStartProcessVersion,
    showVersionResults,
    openSignup,
    setOpenSignup,
    openPopupTerms, 
    setOpenPopupTerms,
  };
};