import React from "react";
import { useNavigate, useLocation } from "react-router";
import { useUserAuth } from "../components/UserAuthContext";
import { customerPortal } from "../utils/stripeUtils";
import { useState, useEffect } from "react";
import { Row, Col, Button, Container, Alert } from 'react-bootstrap';
import styles from '../styles/Profile.module.css'
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosLogOut } from "react-icons/io";
import { MdVerified } from 'react-icons/md';
import logo from '../images/logo/logo_white.svg';
import ProfileBox from "../components/ProfileBox";
import CustomHead from '../components/CustomHead';


const Profilo = () => {
  const { logOut, user, userData, dataReady } = useUserAuth();
  const userType = userData["type"];
  const navigate = useNavigate();
  const location = useLocation();
  const fromSignup = location.state?.fromSignup;
  const [isPageHidden, setPageIsHidden] = useState(true);
  const [pageType, setPageType] = useState();
  const [message, setMessage] = useState("");
  const [messageEmail, setMessageEmail] = useState("");

  useEffect(() => {
    if (fromSignup) {
      // Show popup message
      setMessageEmail("Ti abbiamo inviato una email di verifica, controlla anche la posta indesiderata");
    }
  }, [fromSignup]);

  if (userType === null) {
    return <p>Loading...</p>;
  }

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      //console.log(error.message);
    }
  };

  function handleButtonClick(type) {
    setMessage("");
    if (dataReady) {
      setPageType(type);
      openPage();
    }
  }

  function openPage() {
    document.getElementById("profile").style.display = "none";
    setPageIsHidden(!isPageHidden);
  }

  function closePage() {
    document.getElementById("profile").style.display = "flex";
    setPageIsHidden(!isPageHidden);
  }

  const renderText = () => {
    switch (pageType) {
      case 'details':
        return 'Profilo';
      case 'credit':
        return 'Credito residuo';
      case 'subscription':
        return 'Gestisci abbonamento';
    }
  };

  const handleCustomerPortal = async () => {
    setMessage("");
    if (userType === "lifetime") {
      setMessage("Non hai bisogno di nessun abbonamento, sarai un utente premium per sempre");
      return;
    }
    if (userType === "normal") {
      navigate("/pay");
    }
    const response_portal = await customerPortal();
    if (response_portal != null) {
      setMessage(response_portal.msg);
    }
  };

  return (
    <main className="App">
      <CustomHead
        title="Teseo.app | Profilo"
        description="Profilo personale."
        keywords="teseo.app, teseo, latino, greco, traduzione, analisi, versione"
        author="Teseo"
        canonicalUrl="https://teseo.app/profilo"
      />

      <Container className="pt-20">
        <Row className={`p-lg-4 p-3 m-0 mt-5 justify-content-center gap-3`} id="profile">
          <Row className="d-flex justify-content-start">
            <IoIosArrowDropleftCircle className={`${styles.back} w-fit`} onClick={() => { navigate("/") }} />
          </Row>
          <Col lg={8} md={11} sm={11} xs={11} className="p-0">

            <div className={`${styles.profile_box} pt-3 pb-10 mt-3 d-flex flex-col justify-content-start align-items-center radius-teseo`}>
              <div className={`px-4 d-flex flex-row justify-content-between bold fontXL`}>
                <p>Benvenut*, {userData.nome}!</p>
                <Button className={`${styles.logout_button} d-flex align-items-center gap-1`} onClick={handleLogout} variant="link">
                  <IoIosLogOut fontSize={20}></IoIosLogOut> Esci
                </Button>
              </div>

              <div className={`${styles.profile_info}`} onClick={() => handleButtonClick('details')}>
                <div className={`${styles.info}`}>
                  <div className="d-flex flex-col justify-content-end">
                    <p className="inter-medium fontL pb-2">Profilo</p>
                    <p className="fontM">Le tue informazioni </p>
                  </div>
                  <p className="d-flex align-items-center">
                    {!user || !user?.emailVerified ? (
                      <span className="mx-2 red fontS regular">*Devi verificare la tua email</span>
                    ) : null}
                    <IoIosArrowDroprightCircle />
                  </p>
                </div>
              </div>

              <div className={`${styles.profile_info}`} onClick={() => handleButtonClick('credit')}>
                <div className={`${styles.info}`}>
                  <div>
                    <p className="inter-medium fontL pb-2">Credito residuo </p>
                    <p className="fontM">Verifica il credito residuo per questo mese</p>
                  </div>
                  <IoIosArrowDroprightCircle />
                </div>
              </div>

              <div className={`${styles.profile_info}`} onClick={handleCustomerPortal}>
                <div className={`${styles.info}`}>
                  <div>
                    {userType === 'lifetime' ? <p className="inter-medium fontL pb-2">Sei uno di primi 1000 </p> : <p className="inter-medium fontL pb-2">Gestisci abbonamento </p>}
                    {userType === 'lifetime' || userType === 'premium' ?
                      <p className="fontM d-flex gap-1"><MdVerified fontSize={20}></MdVerified>Fai parte della community!</p> :
                      <p className="fontM">Teseo gratis</p>
                    }
                  </div>
                  <IoIosArrowDroprightCircle />
                </div>
              </div>
            </div>
          </Col>

          {userType === 'normal' && (
            <Col lg={3} md={11} sm={11} xs={11} className={`h-40 cursor-pointer background-teseo-gradient position-relative mt-5 radius-teseo d-flex justify-content-center align-items-center`} onClick={() => navigate("/pay")}>
              <div className="p-lg-5">
                <img src={logo} alt="Logo" className={styles.logo_image} />
                <span className="bold fontXXL mt-4 lh-1">Sblocca Teseo+</span>
              </div>
            </Col>
          )}
        </Row>

        <Row className={`${isPageHidden ? styles.hidden : ''} mt-5 m-0 page`}>
          <Row className={`${styles.box} p-lg-4 m-0 mt-3 p-3 pb-0`}>
            <Col className={`${styles.title} col-12 d-flex flex-row align-items-center justify-content-between`}>
              <IoIosArrowDropleftCircle className={`${styles.back}`} onClick={closePage} />
              <p className="bold fontXL white mx-auto">{renderText()}</p>
            </Col>
          </Row>
          <ProfileBox type={pageType} className="page"></ProfileBox>
        </Row>

        {message &&
          <Row className={`d-flex p-3 flex-col align-content-center`}>
            <Col className="col-12">
              <Alert className={`d-flex flex-row justify-content-between`} variant="danger">
                <span >{message}</span>
                <button type="button" className="btn-close" onClick={() => setMessage("")} aria-label="Close"></button>
              </Alert>
            </Col>
          </Row>
        }

        {messageEmail &&
          <Row className={`d-flex p-3 flex-col align-content-center`}>
            <Col className="col-12">
              <Alert className={`d-flex flex-row justify-content-between`} variant="success">
                <span >{messageEmail}</span>
                <button type="button" className="btn-close" onClick={() => setMessageEmail("")} aria-label="Close"></button>
              </Alert>
            </Col>
          </Row>
        }
      </Container>
    </main>
  );
};

export default Profilo;
