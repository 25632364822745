import { Link } from "react-router-dom";

export default function WrapperDeclension(props) {

    const {declinazione, paradigma, type} = props;

    return (
        <div className="d-flex align-items-center">
          {(declinazione || paradigma) ? (
            <span className="premium-tag inter-semibold my-2">
                {declinazione != null ? <span>{declinazione}</span> : <span>{paradigma}</span>}
            </span>
          ) : (
            type === "normal" && (
              <span className="fontS mx-1 underline">
                <Link to="/pay">Come la cerco nel dizionario?</Link>
              </span>
            )
          )}
        </div>
      );
}