import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Greco from './pages/Greco';
import Login from './pages/login';
import SignupWithGoogle from './pages/signupWithGoogle';
import Signup from './pages/signup';
import Navbar from './components/Navbar';
import CheckoutYes from './stripe/CheckoutYes';
import CheckoutNo from './stripe/CheckoutNo';
import Profilo from './pages/profilo';
import TermsAndConditions from './pages/TermsAndConditions';
import Footer from './components/Footer';
import { UserAuthContextProvider } from './components/UserAuthContext';
import { TextOperationsProvider } from './hooks/TextOperationsContext';
import { NeedRegistrationRoute, AuthenticateRoute, NonAuthenticateRoute, HomeProtectedRoute, AuthenticatedAndRegisteredRoute, OnlyNormalUserRoute } from './components/ProtectedRoutes';
import PaymentPage from './stripe/PaymentPage';
import NotFound from './pages/notfound';
import ScrollToTop from './components/ScrollToTop';


function App() {

  return (
    <UserAuthContextProvider>
      <TextOperationsProvider>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/greco" element={<HomeProtectedRoute><Greco /></HomeProtectedRoute>} />
          <Route path="/login" element={<NonAuthenticateRoute><Login /></NonAuthenticateRoute>} />
          <Route path="/complete-signup" element={<NeedRegistrationRoute><SignupWithGoogle /></NeedRegistrationRoute>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profilo" element={<AuthenticateRoute><Profilo /></AuthenticateRoute>} />
          <Route path="/checkoutyes" element={<AuthenticateRoute><CheckoutYes /></AuthenticateRoute>} />
          <Route path="/checkoutno" element={<AuthenticateRoute><CheckoutNo /></AuthenticateRoute>} />
          <Route path="/pay" element={<HomeProtectedRoute><PaymentPage /></HomeProtectedRoute>} />
          <Route path="/terms" element={<HomeProtectedRoute><TermsAndConditions /></HomeProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
      </TextOperationsProvider>
    </UserAuthContextProvider>
  );
};

export default App;