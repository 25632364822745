import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../components/UserAuthContext";
import GoogleButton from "react-google-button";
import api from '../API/backend';
import styles from "../styles/PopupTeseo.module.css";
import { IoMdClose } from "react-icons/io";

const API_URL = process.env.REACT_APP_BACKEND_PATH;

const Signup = ({ closePopup, popup }) => {
    const { googleSignIn, signUp, setLoading } = useUserAuth();
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nome, setNome] = useState("");
    const [scuola, setScuola] = useState("");
    const [anno, setAnno] = useState("");
    const [error, setError] = useState("");
    const [step, setStep] = useState(1);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [dataConsent, setDataConsent] = useState(false);

    // Phone number verification system - Future implementation
    // const [phoneNumber, setPhoneNumber] = useState("");
    // const [completePhoneNumber, setCompletePhoneNumber] = useState("");
    // const [verificationCode, setVerificationCode] = useState("");
    // const [submitAttempts, setSubmitAttempts] = useState(0);
    // const [message, setMessage] = useState("");

    // const handlePhoneNumberSubmit = async (e) => {
    //     e.preventDefault();
    //     setError("");

    //     if (submitAttempts >= 5) {
    //         setError("Hai superato il numero massimo di tentativi.");
    //         return;
    //     }

    //     if (!phoneNumber) {
    //         setError("Inserisci prima un numero di telefono.");
    //         return;
    //     }

    //     const phoneNumberRegex = /^[0-9]{6,14}$/;
    //     if (!phoneNumberRegex.test(phoneNumber)) {
    //         setError("Inserisci un numero di telefono valido");
    //         return;
    //     }

    //     try {
    //         let fullPhoneNumber = `+39${phoneNumber}`; // Solo Italia
    //         setCompletePhoneNumber(fullPhoneNumber);
    //         const result = await api.post(`${API_URL}/public/user/send-sms-code`, { phone_number: fullPhoneNumber });
    //         if (result.status === 200) {
    //             console.log("Codice inviato");
    //             setMessage("Codice di verifica inviato.");
    //         } else {
    //             setError("Errore nell'invio del codice di verifica. Riprova.");
    //         }
    //         setSubmitAttempts(submitAttempts + 1);
    //     } catch (err) {
    //         setError("Qualcosa è andato storto. Riprova.");
    //         // TODO: migliorare il messaggio di errore
    //     }
    // };

    // const handleVerificationCodeSubmit = async (e) => {
    //     e.preventDefault();
    //     setError("");

    //     if (!verificationCode) {
    //         setError("Inserisci prima il codice di verifica.");
    //         return;
    //     }

    //     const codeRegex = /^[0-9]{6}$/;
    //     if (!codeRegex.test(verificationCode)) {
    //         setError("Inserisci un codice di verifica valido");
    //         return;
    //     }

    //     try {
    //         const result = await api.post(`${API_URL}/public/user/verify-sms-code`, { phone_number: completePhoneNumber, code: verificationCode });
    //         if (result.status === 200) {
    //             setStep(3);
    //             console.log("Codice verificato");
    //         } else {
    //             setError("Errore nella verifica del codice. Riprova.");
    //         }
    //     } catch (err) {
    //         setError("Qualcosa è andato storto. Riprova.");
    //         // TODO: migliorare il messaggio di errore
    //     }
    // };
    //     try {
    //         const result = await api.post(`${API_URL}/public/user/verify-sms-code`, { phone_number: completePhoneNumber, code: verificationCode });
    //         if (result.status === 200) {
    //             console.log("Codice verificato");
    //         } else {
    //             setError("Errore nella verifica del codice. Riprova.");
    //         }

    //         // Verifica se il telefono è già stato registrato
    //         const response = await api.post(`${API_URL}/public/user/check-phone-number-uniqueness`, { phone_number: completePhoneNumber });
    //         if (response.status === 200) {
    //             console.log("Numero di telefono non registrato");
    //             setStep(3);
    //         } else {
    //             setError("Numero di telefono già registrato");
    //             setStep(2);
    //         }

    //     } catch (err) {
    //         setError("Qualcosa è andato storto. Riprova.");
    //         // TODO: migliorare il messaggio di errore
    //     }
    // };

    const handleSubmitStep1 = (e) => {
        e.preventDefault();
        setError("");
        if (scuola && anno && nome) {
            setStep(3);   // mettere a 2 per abilitare verifica cellulare
        } else {
            setError("Completa tutti i campi prima di continuare!");
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        setError("");
        try {
            if (!acceptTerms || !dataConsent) {
                setError("Accetta i termini per entrare in Teseo");
                return;
            }
            const success = await googleSignIn(scuola, anno, nome);
            if (success) {
                navigate("/");
            } else {
                setError("Errore nell'accesso con Google");
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSubmitStep3 = (e) => {
        const phoneNumber = ""; // Future implementation

        e.preventDefault();
        setError("");
        setLoading(true);

        // Ensure all fields are completed before proceeding
        if (!email || !password || !acceptTerms || !dataConsent) {
            setError("Completa tutti i campi prima di continuare!");
            setLoading(false);
            return;
        }

        signUp(email, password)
            .then((user) => {
                if (!user) {
                    throw new Error("Errore nella registrazione. Riprova.");
                }
                const user_uid = user.uid;

                return api.post(`${API_URL}/user/data`, {
                    user_uid,
                    scuola,
                    anno,
                    nome,
                    phoneNumber,
                });
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.clear();
                } else {
                    throw new Error("Errore nella registrazione. Riprova.");
                }
            })
            .then(() => {
                setStep(4);
            })
            .catch((err) => {
                // Handle specific Firebase errors
                if (err.message.includes("auth/email-already-in-use")) {
                    setError("Email già in uso");
                } else if (err.message.includes("auth/weak-password")) {
                    setError("Password troppo debole, deve contenere almeno 6 caratteri");
                } else if (err.message.includes("auth/invalid-email")) {
                    setError("Email non valida");
                } else {
                    setError(err.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Container className="pt-40">
                <div className={styles.normalSizeBox}>
                    <div className={`${popup ? styles.popup_container : ''} d-flex align-items-center justify-content-center background-teseo-component radius-teseo`}>
                        <div className={`${styles.boxTeseo} white p-5`}>
                            {popup ?
                                <div className={`${styles.icon_close} fontL d-flex align-items-center justify-content-center`} onClick={closePopup}>
                                    <IoMdClose />
                                </div>
                                :
                                null
                            }
                            <h2 className="bold text-center fontXL">Registrati</h2>
                            {step === 1 || step === 3 ? (
                                <div className={styles.dots_container}>
                                    <div
                                        className={`${styles.dot} ${step === 1 ? styles.active : ""}`}
                                    > {step === 1 && <span>1</span>} </div>
                                    <div
                                        className={`${styles.dot} ${step === 3 ? styles.active : ""}`}
                                    > {step === 3 && <span>2</span>} </div>
                                </div>
                            ) : null}
                            {step === 1 && (
                                <>
                                    {/* <div className="mb-4 box text-center regular fontM">
                                        Hai già un account? <Link to="/login" className="linkTeseo">Accedi</Link>
                                    </div> */}
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <Form onSubmit={handleSubmitStep1}>
                                        <Form.Group className="mb-3" controlId="formNameSurname">
                                            <Form.Control
                                                type="text"
                                                placeholder="Nome e Cognome"
                                                onChange={(e) => setNome(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formScuola">
                                            <Form.Label>Seleziona la tua scuola:</Form.Label>
                                            <Form.Select
                                                onChange={(e) => setScuola(e.target.value)}
                                            >
                                                <option value="">Seleziona</option>
                                                <option value="classico">Liceo Classico</option>
                                                <option value="classico">Liceo Classico Europeo</option>
                                                <option value="classico">Liceo Classico Internazionale</option>
                                                <option value="scientifico">Liceo Scientifico</option>
                                                <option value="linguistico">Liceo Linguistico</option>
                                                <option value="pedagogico">Liceo Pedagogico</option>
                                                <option value="universita">Università</option>
                                                <option value="altro">Altro</option>
                                            </Form.Select>
                                        </Form.Group>

                                    <Form.Group className="mb-5" controlId="formAnno">
                                        <Form.Label>Seleziona in quale anno sei:</Form.Label>
                                        <Form.Select
                                            onChange={(e) => setAnno(e.target.value)}
                                        >
                                            <option value="">Seleziona</option>
                                            <option value="1">1°</option>
                                            <option value="2">2°</option>
                                            <option value="3">3°</option>
                                            <option value="4">4°</option>
                                            <option value="5">5°</option>
                                            <option value="0">Altro</option>
                                        </Form.Select>
                                    </Form.Group>

                                        <div className="d-grid gap-2">
                                            <Button variant="primary" type="Submit" className="buttonTeseo">
                                                <span className="bold fontL">Continua</span>
                                            </Button>
                                            <span className="regular fontS text-center">Ci siamo, manca solo un passaggio</span>
                                        </div>
                                    </Form>
                                </>
                            )}

                            {/* {step === 2 && (
                            <>
                                {error && <Alert variant="danger">{error}</Alert>}
                                {message && <Alert variant="success">{message}</Alert>}
                                <Form onSubmit={handlePhoneNumberSubmit}>
                                    <Form.Group controlId="formPhoneNumber">
                                        <Form.Label>Numero di telefono</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Es. 3826401638"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button type="submit" className="buttonTeseo bold fontL my-3">Invia codice di verifica</Button>
                                    </div>
                                </Form>

                                <Form onSubmit={handleVerificationCodeSubmit} className="mt-4">
                                    <Form.Group controlId="formVerificationCode">
                                        <Form.Label>Codice di verifica</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Es. 298357"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button type="submit" className="buttonTeseo bold fontL my-3">Verifica</Button>
                                    </div>
                                </Form>
                                <div id="recaptcha-container"></div>
                            </>
                        )} */}

                            {step === 3 && (
                                <>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <Form onSubmit={handleSubmitStep3}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control
                                                type="email"
                                                placeholder="Indirizzo Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="" controlId="formBasicCheckbox1">
                                            <Form.Check
                                                type="checkbox"
                                                onChange={(e) => setAcceptTerms(e.target.checked)}
                                                label={
                                                    <span>
                                                        Accetto i{' '}
                                                        <a
                                                            href="https://www.iubenda.com/termini-e-condizioni/27118703"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className='regular white m-2'
                                                        >
                                                            Termini e Condizioni
                                                        </a>
                                                    </span>
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-5" controlId="formBasicCheckbox2">
                                            <Form.Check
                                                type="checkbox"
                                                onChange={(e) => setDataConsent(e.target.checked)}
                                                label={
                                                    <span>
                                                        Acconsento alla{' '}
                                                        <a
                                                            href="https://www.iubenda.com/privacy-policy/27118703"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className='regular white m-2'
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </span>
                                                }
                                            />
                                        </Form.Group>

                                        <div className="d-grid gap-2">
                                            <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                                Continua
                                            </Button>
                                        </div>
                                        <div className={styles.separator}>
                                            <span className={styles.separator_text}>Oppure</span>
                                            <hr style={{
                                                width: "100%",
                                                margin: "30px",
                                                borderTop: "2px solid #ccc",
                                            }} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <GoogleButton
                                                className="g-btn"
                                                type="light"
                                                onClick={handleGoogleSignIn}
                                            />
                                        </div>
                                    </Form>
                                </>
                            )}
                            {step === 4 && (
                                <>
                                    <img
                                        id="confetti-icon"
                                        src={"confetti-icon.png"}
                                        alt="Confetti"
                                        style={{
                                            width: "150px",
                                            height: "auto",
                                            display: "block",
                                            margin: "20px auto",
                                        }}
                                    />
                                    <p
                                        className="text-center"
                                        style={{
                                            maxWidth: "400px",
                                            margin: "20px auto",
                                        }}
                                    >
                                        Account creato con successo, controlla la tua posta in arrivo
                                        per verificare l'indirizzo email.
                                    </p>
                                    <div className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="Submit"
                                            className="buttonTeseo bold fontL"
                                            onClick={() => navigate("/profilo", { state: { fromSignup: true } })}
                                        >
                                            Continua
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Signup;
