import { loadStripe } from "@stripe/stripe-js";
import api from '../API/backend';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const API_URL = process.env.REACT_APP_BACKEND_PATH;

export const customerPortal = async() => {
    try {

      const headers = {
        "Content-Type": "application/json"
      }

      const response = await api.post(
        `/user/customer-portal`,
        {},
        {
            headers: headers
        }
        );

        if (response.status === 200) {
            const session_url = await response.data;
            window.location.href = session_url.url;
        }
        
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.error('Customer not found:', error.response.data.error);
            return ({
                response: false,
                msg: 'Cliente non trovato, Se hai acquistato Teseo+ contattaci',
            });
        } else {
            console.error('Error redirecting to billing portal:', error);
            return ({
                response: false,
                msg: 'Errore inaspettato, per favore riprova più tardi',
            });
        }
  }
}

export const payTeseo = async (isAnnual) => {
    const stripe = await stripePromise; 

    const response = await api.post(`${API_URL}/create-checkout-session`, { isAnnual: isAnnual });

    const session = await response.data;
    const result = await stripe.redirectToCheckout({
        sessionId:session.id
    });

    if(result.error) {
        console.log(result.error);
    }
}
