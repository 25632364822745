import { useState, useCallback, useEffect } from 'react';
import divideText from '../utils/divideText';
import api from '../API/backend';
import { useUserAuth } from "../components/UserAuthContext";
import { useTextOperations } from './TextOperationsContext';

const API_URL = process.env.REACT_APP_BACKEND_PATH;

// TODO : BETTER HANDLING ERRORI 

export const useAnalysis = () => {

  const { 
    analysis, 
    greekAnalysis, 
    lemma,
    lemmaGreek,
    setAnalysis,
    setGreekAnalysis,
    setLemmas,
    setLemmasGreek,
    setError,
    setProgress, 
  } = useTextOperations();

  const { userData } = useUserAuth();
  /* const { setError, setProgress } = uiState; */


  const handleUpdateAnalysis = useCallback((data, currentIndex, totalParts) => {
    setAnalysis(prev => ({ ...prev, ...data }));
    setProgress(((currentIndex + 1) / totalParts) * 100); 
  }, []);

  const handleUpdateGreekAnalysis = useCallback((data, currentIndex, totalParts) => {
    setGreekAnalysis(prev => ({ ...prev, ...data }));
    setProgress(((currentIndex + 1) / totalParts) * 100);
  }, []);


  const handleUpdateError = useCallback((errorMessage) => {
    setError(errorMessage);
  }, []);



  const fetchLemmas = useCallback(async (inputText, lang) => {
    try {
      const response = await api.post(`${API_URL}/analyze/lemmas`, { text: inputText, lang: lang });
      setLemmas(prev => ({
        ...prev,
        data: [...(prev.data || []), ...(response.data.data || [])]
      }));

      return response;
    } catch (error) {
      handleAnalysisError(error, handleUpdateError);
    }
  }, []);


  const analyzeText = useCallback(async (inputText, lang) => {
    // Reset lemmas before starting the analysis
    setLemmas({ data: [] });
    
    // Input validation
    if (!inputText || !lang) {
        handleUpdateError(new Error('Invalid input: text and language are required'));
        return { status: false, output: {} };
    }

    // Text preprocessing
    const cleanedText = inputText.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\d]/g, '');
    const subtexts = divideText(cleanedText);

    let allSuccessful = true;
    let analysisOutput = {};

    try {
        for (let index = 0; index < subtexts.length; index++) {
            const subtext = subtexts[index];

            try {
                // Prepare API calls based on user type
                const apiCalls = [
                    api.post(`${API_URL}/analyze/dynamic`, { text: subtext, lang })
                ];

                // Conditional lemma fetching based on user type
                if ((index === 0 && userData["type"] === "normal") ||
                    (userData["type"] === "premium" || userData["type"] === "lifetime")) {

                    const lemmaText = index === 0 && userData["type"] === "normal"
                        ? subtext.split(' ').slice(0, 5).join(' ')
                        : subtext;

                    apiCalls.push(fetchLemmas(lemmaText, lang));
                }

                // Execute API calls sequentially
                const [dynamicResponse, lemmaResponse] = await Promise.all(apiCalls);

                // Update analysis with response data
                let analysisUpdated = { ...dynamicResponse.data };

                if (lemmaResponse) {
                    lemmaResponse.data.data.forEach(({ lemma, text }) => {
                        if (analysisUpdated[text]) {
                            analysisUpdated[text] = {
                                ...analysisUpdated[text],
                                lemma,
                            };
                        }
                    });
                }

                // Update the progress and analysis state
                handleUpdateAnalysis(analysisUpdated, index, subtexts.length);
                analysisOutput = { ...analysisOutput, ...analysisUpdated };

            } catch (error) {
                // Handle individual subtext analysis error
                handleAnalysisError(error, handleUpdateError);
                allSuccessful = false;
                console.error(`Analysis failed for subtext ${index}:`, error);
            }
        }

        return { status: allSuccessful, output: analysisOutput };

    } catch (overallError) {
        // Catch any unexpected errors in the entire process
        handleUpdateError(overallError);
        return { status: false, output: {} };
    }
}, [handleUpdateAnalysis, handleUpdateError, fetchLemmas, userData]);

  



  const analyzeTextGreek = useCallback(async (inputText, lang) => {
    // Remove unwanted characters and numbers from input text
    const cleanedText = inputText.replace(/[.,\/#!$%^&*;:{}=\-_`~()\d]/g, '');
    // Split the cleaned text into manageable subtexts
    const subtexts = divideText(cleanedText);
    
    let allSuccessful = true; // Track if all API calls are successful
    const collectedLemmas = { data: [] }; // Store all collected lemmas
    let greekResult = {}; // Combined results from all API calls
  
    for (let i = 0; i < subtexts.length; i++) {
      try {
        // Make API call to analyze Greek text
        const response = await api.post(`${API_URL}/analyze/greek`, {
          text: subtexts[i],
          lang: lang
        });
  
        // Process the response
        const modifiedResponse = Object.entries(response.data).reduce((acc, [word, features]) => {
          // Collect lemmas in the required format
          if (features.lemma) {
            collectedLemmas.data.push({ lemma: features.lemma, text: word });
          }
  
          // Exclude the lemma property and add remaining features to the modified response
          const { lemma, ...rest } = features;
          acc[word] = rest;
          return acc;
        }, {});
  
        // Pass the modified response to the update function
        handleUpdateGreekAnalysis(modifiedResponse, i, subtexts.length);
  
        // Merge the modified response into the final result
        greekResult = { ...greekResult, ...modifiedResponse };
      } catch (error) {
        // Log the error and handle it appropriately
        console.error(error);
        handleAnalysisError(error, handleUpdateError);
        allSuccessful = false; // Mark the process as unsuccessful
      }
    }
  
    // Update the state with the collected lemmas
    setLemmasGreek(prev => ({ ...prev, ...collectedLemmas }));
  
    // Return the final status and result
    return { status: allSuccessful, output: greekResult };
  }, [handleUpdateGreekAnalysis, handleUpdateError, setLemmasGreek]);






  const handleAnalyzeText = useCallback(async (inputText, lang) => {
    try {
      setError('');
      setProgress(0);

      let analysisResult;

      if(lang === "lat"){
        setAnalysis({});
        setLemmas({});
        analysisResult = await analyzeText(inputText.toLowerCase(), lang);
        
        /* if(userData["type"] === "lifetime" || userData["type"] === 'premium'){
          const lemmas = await fetchLemmas(inputText, lang);
        }
        if (userData["type"] === "normal") {
          const firstFiveWords = inputText.split(' ').slice(0, 5).join(' ');
          console.log(firstFiveWords);
          const lemmas = await fetchLemmas(firstFiveWords, lang);
        } */
      }

      else if(lang === "grc") {
        setGreekAnalysis({});
        setLemmasGreek({});
        analysisResult = await analyzeTextGreek(inputText.toLowerCase(), lang);
      }

      return ({
        status: analysisResult.status,
        outputAnalysis: analysisResult.output
      })
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      
    }
  }, []);

  return {
    analysis,
    greekAnalysis,
    lemma,
    lemmaGreek,
    handleAnalyzeText
  };
};

const handleAnalysisError = (error, updateError) => {
  const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
  };
  
  let errorMessage = 'Teseo non risponde';

  if (error.response) {
    const statusCode = error.response.status;
    errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
  } else if (error.request) {
    console.error('Request Error:', error.request);
  } else {
    console.error('Request Setup Error:', error.message);
  }

  updateError(errorMessage);
  throw new Error(errorMessage);
};