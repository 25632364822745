import { Link } from "react-router-dom";
import analisiLogo from "../../images/icons/analisi-icon.svg";

export default function WrapperLemma(props) {
  const { lemma, type } = props;

  return (
    <div className="d-flex align-items-center">
      {lemma ? (
        <span className="premium-tag inter-regular d-flex flex-row gap-2 py-1">
          Cerca come:
          <button
            className="d-flex flex-row justify-content-center align-items-center inter-medium radius-card px-2"
            style={{
              backgroundColor: "#161616",
              border: "solid #F1A849 0.5px",
            }}
          >
            <img
              src={analisiLogo}
              alt="Translation Icon"
              width={16}
              height={16}
              className="mx-1"
            />
            "{lemma.replace(/[0-9.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')}"
          </button>
        </span>
      ) : (
        type === "normal" && (
          <span className="fontS mx-1 underline">
            <Link to="/pay">Come la cerco nel dizionario?</Link>
          </span>
        )
      )}
    </div>
  );
}