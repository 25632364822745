import { FaInstagram, FaTiktok } from 'react-icons/fa';
import styles from '../styles/Footer.module.css'

export default function Footer() {
    return (
        <div className={`${styles.footerContainer} background-teseo-component mt-20`}>
            <div className={`${styles.textContainer}`}>
                <p className={`${styles.text} inter-regular text-center me-lg-1`}>Vuoi collaborare con Teseo? Scrivici! </p>
                <p className={`${styles.textBold} inter-regular text-center`}>teseotraduce@gmail.com</p>
            </div>

            <div className={`${styles.linkContainer} d-flex`}>
                <div className={`${styles.linkGroup} d-flex flex-row justify-content-center`}>
                    <a href="https://www.iubenda.com/privacy-policy/27118703" target="_blank" className={`${styles.link} inter-regular white cursor-pointer align-content-center`}>Privacy Policy</a>
                    <a href="https://www.iubenda.com/privacy-policy/27118703/cookie-policy" target="_blank" className={`${styles.link} inter-regular white cursor-pointer align-content-center`}>Cookie Policy</a>
                    <a onClick={() => { window.open("/terms", "_blank") }} className={`${styles.link} inter-regular white cursor-pointer align-content-center`}>Termini e Condizioni</a>
                </div>
                <div className={`${styles.iconContainer} d-flex flex-row`}>
                    <a href="https://www.instagram.com/teseo.app" className={`${styles.socialIcon} socialTeseo align-content-center`} target="_blank">
                        <FaInstagram size={27} style={{ marginRight: ' 1rem' }} />
                    </a>
                    <a href="https://www.tiktok.com/@teseoappp" className={`${styles.socialIcon} socialTeseo align-content-center`} target="_blank">
                        <FaTiktok size={27} />
                    </a>
                </div>
            </div>
        </div>
    );
}