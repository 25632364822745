import { useEffect, useState } from "react";
import { FaClockRotateLeft } from "react-icons/fa6";
import styles from "../styles/History.module.css";
import { useUserAuth } from "./UserAuthContext";
import api from "../API/backend";
import { useTextOperations } from "../hooks/TextOperationsContext";

export default function History({ language, setInputText, setDisabled }) {
    const [historyData, setHistoryData] = useState([]);
    const { userData } = useUserAuth();
    const [loading, setLoading] = useState(true);

    const {
        setTranslation,
        setGreekTranslation,
        setAnalysis,
        setGreekAnalysis
    } = useTextOperations();

    const historyHandler = async () => {
        try {
            const historyRes = await api.get(`${process.env.REACT_APP_BACKEND_PATH}/history`, {
                params: {
                    lang: language,
                    limit: 5
                }
            });
            if (historyRes.status !== 200) {
                throw new Error('Error getting upload link');
            }

            return historyRes.data;

        } catch (error) {
            console.error('Error connecting to server:', error);
        }
    };

    const date = (timestamp) => {
        const milliseconds = (timestamp._seconds * 1000) + (timestamp._nanoseconds / 1000000);
        const date = new Date(milliseconds);
        const formattedDate = date.toLocaleDateString('it-IT', { weekday: "long", year: "numeric", month: "long", day: "numeric" });
        const hour = new Date(milliseconds)
        const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
        const formattedTime = formatter.format(hour);

        return formattedDate.concat(", ", formattedTime);
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (userData["isRegistered"]) {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [userData]);

    useEffect(() => {
        const fetchHistory = async () => {
            if (!loading) {
                const data = await historyHandler();
                if (data) {
                    setHistoryData(data);
                }
            }
        };

        fetchHistory();
    }, [loading]);

    const onHistoryClick = async (input, translation, analysis) => {
        // Set input text
        setInputText(input);
        setDisabled(true);

        // Set translation/analysis text
        if (language === "lat") {
            setTranslation(translation);
            setAnalysis(analysis);
        }
        else {
            setGreekTranslation(translation);
            setGreekAnalysis(analysis);
        }
    }

    if (!userData) {
        return <>
            <div className={`${styles.historyWrap} d-flex flex-col mt-lg-4 mt-0 radius-box background-teseo-component`}>
                <div className={`${styles.title} d-flex flex-row regular align-items-center`}>
                    <p>Cronologia</p>
                    <FaClockRotateLeft size='1.4em' />
                </div>
                <p>Nessuna versione. Inizia a tradurre!</p>
            </div>
        </>
    }

    if (loading) {
        return <>
            <div className={`${styles.historyWrap} d-flex flex-col mt-lg-4 mt-0 radius-box background-teseo-component`}>
                <div className={`${styles.title} d-flex flex-row regular align-items-center`}>
                    <p>Cronologia</p>
                    <FaClockRotateLeft size='1.4em' />
                </div>
                <p>Caricamento...</p>
            </div>
        </>
    }

    return (
        <>
            <div className={`${styles.historyWrap} d-flex flex-col mt-lg-4 mt-0 radius-box background-teseo-component`}>
                <div className={`${styles.title} d-flex flex-row regular align-items-center`}>
                    <p>Le mie versioni</p>
                    <FaClockRotateLeft size='1.4em' />
                </div>

                <ul className={`${styles.list} d-flex flex-col regular`}>
                    {historyData.length === 0 ? (
                        <p>Nessuna versione. Inizia a tradurre!</p>
                    ) : historyData.map((element, index) => (
                        <li key={index} className={`${styles.listItem} d-flex flex-col`} onClick={() => {
                            onHistoryClick(element.input, element.translation, element.analysis);
                        }}>
                            <p className={`${styles.subtitle}`}>{element.input}</p>
                            <p className={`${styles.text} text-capitalize`}>{date(element.timestamp)}</p>
                            {element.analysis !== null
                                ? <p className={`${styles.text}`}>Traduzione/Analisi</p>
                                : <p className={`${styles.text}`}>Traduzione</p>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}
