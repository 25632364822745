import { useUserAuth } from '../components/UserAuthContext';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { payTeseo } from "../utils/stripeUtils";
import SubscriptionCard from "./SubscriptionCard";
import FreePlanCard from "./FreePlanCard";
import { useNavigate } from "react-router";
import CustomHead from '../components/CustomHead';


const PaymentPage = () => {
    const navigate = useNavigate();

    const { user, userData, dataReady } = useUserAuth();
    const [emailVerified, setEmailVerified] = useState(true);
    const [isAnnual, setIsAnnual] = useState(true);
    const [activeTab, setActiveTab] = useState('annuale');
    const [error, setError] = useState('');
    const [disabledPay, setDisabledPay] = useState(false);

    const handleTab = (tab) => {
        setActiveTab(tab);
        setIsAnnual(tab === 'annuale' ? true : false);
    }


    useEffect(() => {
        if (user) {
            setEmailVerified(user.emailVerified);
        }
    }, [user]);

    const goToCheckout = () => {

        if (!user) {
            setError("Accedi per passare a Teseo plus!");
            navigate("/login");
            return;
        }

        if (!emailVerified) {
            setError("Verifica la tua email per passare a Teseo plus!");
            return;
        }

        if (userData["type"] === 'premium' || userData["type"] === 'lifetime') {
            setError("Sei già nella community di Teseo!");
            return;
        }

        try {
            setError(""); // Clear any previous errors
            setDisabledPay(true);
            // Await the async operation
            payTeseo(isAnnual);
        } catch (error) {
            console.error('Checkout error:', error);
            setError("Errore, Teseo plus al momento non disponibile");
        } finally {
            // Only set disabled to false if there was an error
            // If successful, we probably don't want to enable the button again
            // as the user will be redirected to Stripe
            if (error) {
                setDisabledPay(false);
            }
        }
    }

    return (
        <main className='App'>
            <CustomHead
                title="Teseo.app | Piani"
                description="Traduci e analizza la tua versione con l'intelligenza artificiale."
                keywords="teseo.app, teseo, latino, greco, traduzione, analisi, versione"
                author="Teseo"
                canonicalUrl="https://teseo.app/pay"
            />

            <Container className="d-flex flex-column align-items-center justify-content-center pt-10 px-4">
                <Row>
                    <h2 className='text-center white fontXXL bold py-20'> Scegli il tuo piano </h2>
                </Row>
                {error && (
                    <Row className={`d-flex p-3 flex-col align-content-center`}>
                        <Col className="col-12">
                            <Alert
                                className={`d-flex flex-row justify-content-between transition-opacity duration-500 ease-in-out opacity-0 ${error ? 'opacity-100' : 'opacity-0'
                                    }`}
                                variant="warning"
                            >
                                <span className="mx-2">{error}</span>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setError("")}
                                    aria-label="Close"
                                ></button>
                            </Alert>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col lg={6} ms={12} xs={12} className="mb-4 mb-lg-0">
                        <FreePlanCard userType={userData.type} />
                    </Col>
                    <Col lg={6} ms={12} xs={12}>
                        <SubscriptionCard activeTab={activeTab} handleTab={handleTab} disabledPay={disabledPay} goToCheckout={goToCheckout} dataReady={dataReady} user={user}></SubscriptionCard>
                        {activeTab === 'annuale' && <span className='text-center fontL regular w-full d-flex justify-content-center'>*Sì, come un'ora di ripetizioni.</span>}
                    </Col>
                </Row>

            </Container>
        </main>
    );
}

export default PaymentPage;